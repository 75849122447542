import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import '../styles/results.scss'
import { StaticImage } from 'gatsby-plugin-image'
import FindADentist from '../components/find-dentist'

const Results = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Lumineers Reviews | Lumineers Smile Makeover Testimonials"
          description="Lumineers reviews are in! A Lumineers smile makeover can have a positive impact on all aspects of life. Watch Lumineers patient smile makeover testimonials"
        />
        <div className="results-video-container pt-5 pb-5">
          <Container>
            <div>
              <Row className="">
                <Col md="12">
                  <StaticImage
                    src="../images/Woman-Meeting-Lumineers-1200x400.jpg"
                    alt="Woman looking in mirror"
                    placeholder="none"
                    layout="fullWidth"
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <Container>
            <Row className="mt-5">
              <Col md={{ size: 10, offset: 1 }}>
                <h1 className="text-center" style={{ color: `#fff` }}>
                  Lumineers<sup>®</sup> Smile Makeovers Change Lives
                </h1>
                <p>
                  The Lumineers® reviews are in! A Lumineers smile makeover can
                  have a positive impact on all aspects of life — from improved
                  self-esteem and a more successful social life to improved
                  career opportunities. A confident smile conveys that you have
                  the ability to meet life’s challenges with conviction. Watch
                  our Lumineers patient testimonials to see how Lumineers smile
                  makeovers have improved the lives of patients in unexpected
                  ways.{' '}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="mt-5">
            <Col md={{ size: 10, offset: 1 }}>
              <h1 className="text-center">Real People</h1>
              <h3>Annie’s Story</h3>
              <div class="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                  src="https://www.youtube.com/embed/1nFagEU7Hos"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Lumineers Alanta"
                ></iframe>
              </div>
              <h3>Kim’s Story</h3>
              <div class="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                  src="https://www.youtube.com/embed/nEP8WWC90f8"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Lumineers Alanta"
                ></iframe>
              </div>
              <h3>Matt’s Story</h3>
              <div class="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                  src="https://www.youtube.com/embed/TBQ2jy30DtY"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Lumineers Alanta"
                ></iframe>
              </div>
              <h3>Loretta’s Story</h3>
              <div class="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                  src="https://www.youtube.com/embed/jcZl0tmKVWU"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Lumineers"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
        <FindADentist></FindADentist>
      </Layout>
    </>
  )
}

export default Results
